import React from "react";
import styled from "@emotion/styled";

import { Color } from "../../utils/color";
import { Checkbox } from "./checkbox";

export const ItemList = [
  { itemName: "ゴルフ関連製品", test: "test" },
  { itemName: "カメラ関連製品", test: "test" },
  { itemName: "医療関連製品", test: "test" },
  { itemName: "ソフトウェア", test: "test" },
  { itemName: "付属ソフト", test: "test" },
  { itemName: "ハードウェア", test: "test" },
];

export const ItemSelect = () => {
  return (
    <SInput>
      <span>関心のある商品（複数選択可）</span>
      {ItemList.map(({ itemName }) => {
        return <Checkbox key={itemName} itemName={itemName} />;
      })}
    </SInput>
  );
};

const SInput = styled.div`
  margin-top: 3rem;
  display: flex;
  flex-direction: column;
  span {
    display: inline-flex;
    flex-direction: column;
    font-size: 0.9rem;
    font-weight: 500;
    color: ${Color.dark};
    line-height: 1;
  }
`;

import React from "react";
import styled from "@emotion/styled";

import { Color } from "../../utils/color";

export const ContentTextarea = () => {
  return (
    <STextarea>
      <label>
        *お問い合わせ内容
        <textarea rows="10" placeholder="XXX製品の無料デモのご相談です。" />
      </label>
    </STextarea>
  );
};

const STextarea = styled.div`
  margin-top: 3rem;
  label {
    display: flex;
    width: 100%;
    flex-direction: column;
    font-size: 0.9rem;
    font-weight: 500;
    color: ${Color.dark};
    line-height: 1;
    textarea {
      border: none;
      border: 1px solid ${Color.light};
      background-color: ${Color.light};
      border-radius: 8px;
      padding: 1rem 1.5rem;
      font-size: 0.9rem;
      line-height: 1.1;
      transition: border 0.2s;
      margin-top: 1.5rem;
      &:focus {
        outline: none;
        border: 1px solid ${Color.primary};
      }
    }
  }
`;

import React from "react";
import styled from "@emotion/styled";
import { Color } from "../../utils/color";

export const Checkbox = (props) => {
  return (
    <SLabel>
      <input type="checkbox" />
      <button tabIndex="-1">
        <i className="material-icons-outlined">done</i>
      </button>
      {props.itemName}
    </SLabel>
  );
};

const SLabel = styled.label`
  display: inline-flex;
  align-items: center;
  margin-top: 1.5rem;
  margin-left: 0.5rem;
  border-radius: 8px;
  padding: 0;
  font-size: 0.9rem;
  font-weight: 500;
  line-height: 1;
  transition: border 0.2s;
  cursor: pointer;
  user-select: none;
  button {
    width: 1.5rem;
    height: 1.5rem;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background-color: ${Color.gray};
    margin-right: 0.5rem;
    border-radius: 8px;
    transition: all 0.2s;
    border: none;
    border: 1px solid ${Color.gray};
    &:focus {
      outline: none;
      border-color: ${Color.primary};
    }
    i {
      transition: all 0.2s;
      color: ${Color.gray};
      transform: scaleX(0);
    }
  }
  input[type="checkbox"] {
    /* display: none; */
    opacity: 0;
    transform: scale(1.7);
    display: inline-flex;
    z-index: 1;
    margin-right: -1.2rem;
    &:checked {
      + button {
        background-color: ${Color.primary};
        border-color: ${Color.primary};
        i {
          color: ${Color.light};
          transform: scaleX(1);
        }
      }
    }
    &:focus {
      + button {
        border-color: ${Color.primary};
      }
    }
  }

  &:focus {
    outline: none;
    border: 1px solid ${Color.primary};
  }
`;

import React from "react";
import { Link } from "gatsby";
import styled from "@emotion/styled";
import { Color } from "../../utils/color";

import { ContainerWrapper } from "../wrapper/containerWrapper";
import { SectionHeading } from "../heading/sectionHeading";
import { Flow } from "../form/flow";
import { CompanyInput } from "../form/companyInput";
import { NameInput } from "../form/nameInput";
import { MailInput } from "../form/mailInput";
import { ItemSelect } from "../form/itemSelect";
import { ContentTextarea } from "../form/contentTextarea";
import { Checkbox } from "../form/checkbox";

export const ContactFormSection = () => {
  return (
    <SSection>
      <ContainerWrapper>
        <SHeading>
          <SectionHeading jp="お問い合わせ" en="Contact" position="center" />
        </SHeading>
        <Flow flow01="true" flow02="false" flow03="false" />
        <SForm>
          <CompanyInput />
          <NameInput />
          <MailInput />
          <ItemSelect />
          <ContentTextarea />
          <SBtn>
            <div>
              <Checkbox itemName="プライバシーポリシーに同意する" />
            </div>
            <div>
              <Link to="/contact/confirm/">確認画面に進む</Link>
            </div>
          </SBtn>
        </SForm>
      </ContainerWrapper>
    </SSection>
  );
};

const SSection = styled.section`
  padding: 4rem 0;
  /* ---------- */
  /* TB */
  @media screen and (max-width: 1023px) {
    padding: 2rem 0;
    width: 100%;
    .container {
      flex-direction: column;
    }
  }
  /* ---------- */
  /* TB */
  @media screen and (max-width: 599px) {
  }
`;

const SHeading = styled.div`
  text-align: center;
`;

const SForm = styled.form`
  width: 800px;
  margin: auto;
  margin-top: 2rem;

  /* TB */
  @media screen and (max-width: 1023px) {
    width: 100%;
  }
`;

const SBtn = styled.div`
  margin-top: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  div {
    text-align: center;
    &:first-of-type {
      margin-top: 0;
      line-height: 1;
    }
    &:last-of-type {
      margin-top: 2rem;
    }
    label {
      margin: 0;
    }
    a {
      padding: 1rem 2rem;
      display: inline-flex;
      color: ${Color.light};
      background-color: ${Color.primary};
      border-radius: 8px;
      text-decoration: none;
      font-weight: 500;
      font-size: 0.9rem;

      justify-content: center;
      min-width: 320px;
    }
  }
  /* TB */
  @media screen and (max-width: 1023px) {
    div {
      a {
        width: 100%;
      }
    }
  }
`;

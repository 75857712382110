import React from "react";
import { Link } from "gatsby";

import { Breadcrumbs } from "../components/breadcrumbs/breadcrumbs";
import { Layout } from "../components/layout/layout";
import { ContactFormSection } from "../components/section/contactFormSection";

const ContactPage = () => {
  return (
    <Layout title="お問い合わせ | LIME" jpUrl="/contact/" enUrl="/en/contact/">
      <Breadcrumbs>
        <Link to="/" tabIndex="-1">
          ホーム
        </Link>
        <span>/</span>
        <Link to="/contact/" tabIndex="-1">
          お問い合わせ
        </Link>
        <span>/</span>
      </Breadcrumbs>
      <ContactFormSection />
    </Layout>
  );
};

export default ContactPage;
